<template>
  <div>
    <router-link :to="route" target="_blank">
      <v-icon left>fa fa-external-link-alt</v-icon>
      <span>{{label}}</span>
    </router-link>
  </div>
</template>

<script lang="babel" type="text/babel">
import listDataItem from '@/components/list/mixins/listDataItem'
export default {
  mixins: [listDataItem],
  data: () => ({
    
  }),
  created() {},
  computed: {
    label() {
      return this.row.email
    },
    route() {
      return {
        name: 'site-admin-update',
        params: { target: this.row.site_admin_id },
      }
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>